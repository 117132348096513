import { ageBoundaries } from '@finn/ua-constants';
import { CountryCode, getAgeInYears } from '@finn/ui-utils';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

// 1 MB = 1024 KB
// 1 KB = 1024 B
// This mean 10MB
const MAX_FILE_SIZE = 10485760;
const SUPPORTED_FILES_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/pdf',
  'image/heic',
];

export const validateFile = (
  key: string,
  side?: string,
  isRequired: boolean = true
) => {
  const isFrontEnd = side === 'frontend';
  // Because we need Localization keys in frontend messages
  const validationMessages = {
    required: isFrontEnd ? 'yup.required' : `${key} is required`,
    format: isFrontEnd
      ? 'yup.unsupportedFileFormat'
      : `${key} has Unsupported Format (png / jpg / pdf / heic)`,
    size: isFrontEnd
      ? 'yup.MaxSize10'
      : `${key} size too large (Max size is 10 MB)`,
  };

  const schema = isRequired
    ? Yup.mixed().required(validationMessages.required)
    : Yup.mixed().notRequired();

  return schema
    .test('fileSize', validationMessages.size, (value: File) =>
      value ? value.size <= MAX_FILE_SIZE : true
    )
    .test('fileFormat', validationMessages.format, (value: File) =>
      value ? SUPPORTED_FILES_TYPES.includes(value?.type.toLowerCase()) : true
    );
};

export const checkPhoneNumberValidation = (
  phone?: string,
  region?: CountryCode
) => {
  return isValidPhoneNumber(phone ?? '', region);
};

export const isZipCodeValidForGermany = (zipCode?: string) => {
  // very basic validation to ensure digits and length = 5
  const zipCodeRegex = /^\d{5}$/;

  return zipCodeRegex.test(zipCode || '');
};

export const validGermanZipCode = () => {
  const testName = 'zipCode';

  return Yup.string()
    .required('yup.required')
    .test(testName, 'yup.validZipCode', isZipCodeValidForGermany)
    .nullable();
};

export const isValidAge = (value: Date) => {
  const minAge = ageBoundaries.MIN_EXCEPTIONAL;

  return getAgeInYears(value) >= minAge;
};

export const isValidPhone = (
  key: string,
  region: CountryCode = CountryCode.DE
) =>
  Yup.string()
    .required('yup.required')
    .test(key, 'yup.validPhoneNumber', (value) =>
      checkPhoneNumberValidation(value, region)
    )
    .nullable();

export const validNameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
export const validCompanyNameRegex =
  /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð \-!$%^&*()_+|~=`"'{}[:;<>?,.@#\]]+$/u;

/*
  * Here is an explanation:

    /^
      (?=.*\d)          // should contain at least one digit
      (?=.*[a-zA-Z])    // should contain at least one letter
      [a-zA-Z0-9!@#$%^&*)(+=._-]{8,}   // should contain at least 8 from the mentioned characters
    $/
   */
export const validatePasswordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;

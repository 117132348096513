import { Input, InputProps } from '@finn/design-system/atoms/input';
import { useIntl } from '@finn/ui-utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { FunctionComponent, Ref } from 'react';

import { PasswordStrengthCheck } from './PasswordStrengthCheck';

type Props = InputProps & {
  enablePasswordStrengthCheck?: boolean;
  validationError?: boolean | string | null;
};

// This is likely dead code, let's delete it
export const PasswordInput: FunctionComponent<Props> = (props) => {
  const i18n = useIntl();
  const { inputRef, enablePasswordStrengthCheck, validationError, ...rest } =
    props; // exlude the values we props we don't to change
  const parentRef = useAutoAnimate({});

  const errorMessage =
    typeof validationError === 'string' ? validationError : '';

  return (
    <div ref={parentRef as Ref<HTMLDivElement>}>
      <Input
        key="password"
        type="password"
        autoComplete="off"
        ref={inputRef}
        error={errorMessage ? i18n.formatMessage(errorMessage) : ''}
        {...(rest as unknown as any)}
      />
      {enablePasswordStrengthCheck && (
        <PasswordStrengthCheck value={rest.value as string} />
      )}
    </div>
  );
};
